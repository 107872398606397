import React from "react";
import Telegram from "./asssets/telegram-black.png";
import Facebook from "./asssets/facebook-round.png";
import Linkedin from "./asssets/linkedin-round.png";
import Medium from "./asssets/medium.png";
import Twitter from "./asssets/twitter-round.png";
import Instagram from "./asssets/instagram-round.png";
import logo from "./asssets/Bitorio Final Logo-2.png";
// import tlogo from "./asssets/logo-white.svg";
// import star5 from "./asssets/stars-0.svg";
import { Link } from "react-router-dom";
import TrustBox from "./TrustBox";

const Footers = () => {
  return (
    <footer className="w-full bg-gradient-to-l from-brown1 to-brown flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center text-white lg:w-2/3 w-11/12 md:px-8 px-0 py-4">
        <div className="flex justify-center pb-4">
          <img src={logo} alt="" className="" />
        </div>
        <div className="flex justify-center items-center md:gap-8 gap-5 w-full md:px-4 px-0 py-4">
          <a href="https://t.me/bitoriotoken" target="_blank" rel="noreferrer">
            <img src={Telegram} alt="" className="h-10" />
          </a>
          <a
            href="https://www.facebook.com/Bitorio-104431838795253/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="" className="h-10" />
          </a>
          <a
            href="https://twitter.com/bitoriotoken?t=zP3_t4LR2FfKfubM5ZnqxA&s=08"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} alt="" className="h-10" />
          </a>
          <a
            href="https://instagram.com/bitorio.io?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="" className="h-10" />
          </a>
          <a
            href="https://www.linkedin.com/company/btro-io"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} alt="" className="h-10" />
          </a>
          <a
            href="https://medium.com/@btro.io"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Medium} alt="" className="h-10" />
          </a>
        </div>
        <div className="flex whitespace-nowrap md:justify-center justify-evenly items-center gap-2 md:text-base text-sm font-medium md:py-6 py-4 border-t w-full border-white">
          <div className="flex md:flex-row flex-col md:gap-10 gap-4 justify-center w-1/2 items-start">
            <div>
              <Link to="/">
                <span>Home</span>
              </Link>
            </div>
            <div className="md:text-left text-right">
              <Link to="/our-token">
                <span>Our token</span>
              </Link>
            </div>
            <div>
              <Link to="/about">
                <span>Company</span>
              </Link>
            </div>
          </div>
          <div className="flex md:flex-row flex-col md:gap-10 gap-4 justify-center w-1/2 items-end">
            <div>
              <Link to="/license">
                <span>License</span>
              </Link>
            </div>
            <div>
              <Link to="/privacy-policy">
                <span>Privacy Policy</span>
              </Link>
            </div>
            <div>
              <Link to="/contact">
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </div>
        <TrustBox />
      </div>
      <div className=" bg-gray-600 text-white w-full flex justify-center items-center py-4">
        <div className="flex flex-col text-xs w-11/12 justify-center items-center text-justify">
          <h1 className="font-semibold text-sm">Disclaimer</h1>
          <p>
            Please read this disclaimer carefully before using the service
            operated by Bitorio. The content displayed on the website is merely
            for educational and information purposes. It is not intended as a
            substitute for professional advice, should you decide to act upon
            any information based on this website, you shall do it at your own
            risk.; While the information on this website is verified to the best
            of our abilities, we cannot guarantee that there are no mistakes or
            error. We reserve the rights to change our policies at any given
            time, of which you will be promptly updated. If you want to make
            sure that you are up to date with the latest changes, we advise you
            to frequently visit our website.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
