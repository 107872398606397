import React from "react";
import logo from "./asssets/Bitorio Final Logo-2.png";
import { CgClose } from "react-icons/cg";
import { NavLink, useNavigate } from "react-router-dom";
import Telegram from "./asssets/telegram-black.png";
import Facebook from "./asssets/facebook-round.png";
import Twitter from "./asssets/twitter-round.png";
import Instagram from "./asssets/instagram-round.png";
import Linkedin from "./asssets/linkedin-round.png";
import Medium from "./asssets/medium.png";
import Wallet from "./common/Popup";
import Wallet1 from "./common/Popup";
import * as Yup from "yup";
import { Formik } from "formik";
import { API_URL } from "../config/api-url.constants";

const Drawer = ({ setDrawer }) => {
  let activeStyle = {
    color: "white",
  };

  const navigate = useNavigate()

  // const [open, setOpen] = React.useState(false);
  // const [open1, setOpen1] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleOpen1 = () => {
  //   setOpen1(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose1 = () => {
  //   setOpen1(false);
  // };

  // const handleSubmitForm = async (val) => {
  //   const data = {
  //     email: val.email,
  //   };

  //   try {
  //     const fetchResponse = await fetch(API_URL.VerifyEmail, {
  //       method: "POST",
  //       body: JSON.stringify(data),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "origin": "*",
  //       },
  //     });
  //     let response = await fetchResponse.json();
  //     console.log(response)
  //     if (response.message === "Email already exist") {
  //       handleClose()
  //       setDrawer(false)
  //       navigate("/transaction", { state: { email: val.email } })
  //     } else {
  //       handleOpen1();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .matches(
  //       "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
  //       "Enter a valid email"
  //     )
  //     .required("Required"),
  // });

  return (
    <>
      <div className="fixed h-screen flex flex-col text-white bg-gradient-to-l from-blue1 to-blue2 text-xl font-semibold top-0 left-0 bottom-0 right-0 w-full z-30 overflow-hidden">
        <div className="flex flex-col p-8 h-full">
          <div className="flex items-center justify-between w-full">
            <div>
              <NavLink
                to="/"
                className="cursor-pointer pb-4"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                onClick={() => setDrawer(false)}
              >
                <img src={logo} alt="" className="h-12" />
              </NavLink>
            </div>
            <CgClose
              className="md:text-4xl text-3xl cursor-pointer text-white"
              onClick={() => setDrawer(false)}
            />
          </div>
          <div className="flex flex-col justify-center items-start gap-4 md:text-4xl text-2xl font-extrabold flex-1">
            <NavLink
              to="/"
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/our-token"
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Our Token
            </NavLink>
            <NavLink
              to="/about"
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Company
            </NavLink>
            <div className="px-4 md:text-3xl text-xl flex flex-col font-semibold">
            <NavLink
              to="/about"
              exact
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              About Us
            </NavLink>
            <NavLink
              to="/loyalty"
              exact
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Loyalty Levels
            </NavLink>
            {/* <NavLink
              to="/roadmap"
              exact
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Roadmap
            </NavLink>
            <NavLink
              to="/about"
              exact
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Marketing Deck
            </NavLink> */}
          </div>
            <NavLink
              to="/license"
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              License
            </NavLink>
            <NavLink
              to="/contact"
              className="cursor-pointer pb-4"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => setDrawer(false)}
            >
              Contact
            </NavLink>
            {/* <button
              className="bg-white outline-none text-blue2 p-2 font-semibold text-lg rounded-lg"
              onClick={handleOpen}
            >
              Get Wallet Address
            </button> */}
          </div>
          <div className="flex justify-evenly w-full gap-4 items-center text-4xl">
            <a
              href="https://t.me/bitoriotoken"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Telegram} alt="" className="h-10" />
            </a>
            <a
              href="https://www.facebook.com/Bitorio-104431838795253/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="" className="h-10" />
            </a>
            <a
              href="https://twitter.com/bitoriotoken?t=zP3_t4LR2FfKfubM5ZnqxA&s=08"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="" className="h-10" />
            </a>
            <a
              href="https://instagram.com/bitorio.io?utm_medium=copy_link"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} alt="" className="h-10" />
            </a>
            <a
              href="https://www.linkedin.com/company/btro-io"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="" className="h-10" />
            </a>
            <a
              href="https://medium.com/@btro.io"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Medium} alt="" className="h-10" />
            </a>
          </div>
        </div>
      </div>
      {/* <Wallet open={open} close={handleClose}>
        <div className="px-8 py-8 font-medium flex flex-col gap-3 justify-between items-center w-full ">
          <div className="w-full text-right flex justify-between items-center">
            <h1 className="text-lg font-semibold text-blackish">
              Get Wallet Address
            </h1>
            <CgClose
              className="md:text-2xl text-xl cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className="flex flex-col gap-8 w-full"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-1">
                  <label className="font-medium">Email Address</label>
                  <input
                    type="email"
                    className="border outline-none rounded-lg px-4 py-1"
                    placeholder="Enter Your Email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <span className="text-blue2 text-sm font-medium">
                      {errors.email}
                    </span>
                  )}
                </div>
                <button
                  type="submit"
                  className="bg-gradient-to-r from-blue1 to-blue2 outline-none text-white p-2 rounded-lg"
                >
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Wallet>
      <Wallet1 open={open1} close={handleClose1}>
        <div className="px-2 py-8 font-medium flex flex-col gap-3 justify-between items-center w-full ">
          <h1 className="text-xl font-bold">Please Subscribe first</h1>
          <button
            className="text-base rounded-xl bg-blue1 text-white py-2 px-4 border"
            onClick={() => {
              handleClose1();
              handleClose();
            }}
          >
            Close
          </button>
        </div>
      </Wallet1> */}
    </>
  );
};

export default Drawer;
