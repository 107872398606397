import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { scrollToTop } from "../common/Common";
import { useNavigate } from "react-router-dom";
import Wallet from "../common/Popup";
import Wallet1 from "../common/Popup";
import { API_URL } from "../../config/api-url.constants";
import { BiCopy } from "react-icons/bi";
import QRCode from "react-qr-code";


const validationSchema = Yup.object().shape({
  transaction: Yup.string().required("Required"),
  amount: Yup.number().required("Required").min(5).max(10000),
  toaddress: Yup.string().required("Required"),
  fromaddress: Yup.string().required("Required"),
  email: Yup.string().matches(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
    "Enter a valid email"
  ),
});

const TransactionDetail = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [myaddress, setMyAddress] = useState("");
  const [token, setToken] = useState("");
  const [transactionFee, setTransactionFee] = useState(0);
  const [valid, setValid] = useState(false);

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [disable, setDisable] = useState(true);
  let hashInput = React.createRef();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const navigate = useNavigate();

  let location = useLocation();
  const queryObj = new URLSearchParams(location.search);
  let enterEmail = "";
  let valueEmail = queryObj.get("email");
  // console.log(valueEmail);

  if (valueEmail === null) {
    navigate("/");
  } else {
    enterEmail = valueEmail;
    localStorage.setItem("email", enterEmail);
  }

  const handleValidate = async () => {
    // console.log(hashInput.current.value); setValid(false); setDisable(false)
    const data = {
      txHash: hashInput.current.value,
    };
    try {
      const fetchResponse = await fetch(API_URL.ValidateTxHash, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          origin: "*",
        },
      });
      let response = await fetchResponse.json();
      // console.log(response);
      if (response.code === 200) {
        setTransactionFee(response.transactionFees);
        setDisable(false);
        setValid(false)
      } else {
        setValid(true);
        setDisable(true)
        setTransactionFee(0)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = async (val) => {
    setDisable(true)
    // console.log(val);
    const data = {
      email: val.email,
      transactionHash: val.transaction,
      amount: val.amount,
      toAddress: val.toaddress,
      fromAddress: val.fromaddress,
    };

    try {
      const fetchResponse = await fetch(API_URL.TransactionDetail, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          origin: "*",
        },
      });
      let response = await fetchResponse.json();
      if (response.message === "Success") {
        handleOpen();
        localStorage.clear();
      }
      else if(response.status === 400) {
        handleOpen1();
      }
      else {
        setDisable(false)
      }
    } catch (error) {
      console.log(error);
    }
  };


  const selectAddress = (e) => {
    let ERCaddress = [
      "0x23ad455422dd918467581edfad582e465447e6c6"
    ]; //,    "0xfe20c33febea81e43ee51bd87e7a6062f5a12ddb",
    let TRCaddress = [
      "TQbvSbRdLaNtFgBzH9drkyTJoACp6LEBcK"
    ];//,    "TK8kXhNphgQANSoo9pDNa4gmVftKPQkyuM",
    // let BEPaddress = ["0xfe20c33febea81e43ee51bd87e7a6062f5a12ddb"];
    let SOLaddress = [
      "6VeFU7QrrvjWbtxMRGRhCjtjzY1hz7sR7BJF1ShN2Acd"
    ]; //,    "A4uKweu3J4sUDPCj1kebtogvUEu7zTQ1qxymHwAkEWF3",

    const val = e.target.value.toString().trim();
    // console.log(val);
    setToken(val);
    if (val === "ERC20") {
      // console.log("vale", val);
      setMyAddress(ERCaddress[Math.floor(Math.random() * ERCaddress.length)]);
    } else if (val === "TRC20") {
      // console.log("value", val);
      setMyAddress(TRCaddress[Math.floor(Math.random() * TRCaddress.length)]);
    }
    // else if (val === "BEP20") {
    //   setMyAddress(BEPaddress[Math.floor(Math.random() * BEPaddress.length)]);
    // }
     else if (val === "SOL") {
      setMyAddress(SOLaddress[Math.floor(Math.random() * SOLaddress.length)]);
    }

    return myaddress;
  };

  let transactionD = "";
  let amountD = "";
  let toaddressD = "";
  let fromaddressD = "";

  const copyFunction = () => {
    navigator.clipboard.writeText(myaddress);
    setIsDisplayed(true);
    setTimeout(() => {
      setIsDisplayed(false);
    }, 2000);
  };

  // React.useEffect(() => {
    localStorage.getItem("transaction") &&
      (transactionD = localStorage.getItem("transaction"));
    localStorage.getItem("amount") &&
      (amountD = localStorage.getItem("amount"));
    localStorage.getItem("toaddress") &&
      (toaddressD = localStorage.getItem("toaddress"));
    localStorage.getItem("fromaddress") &&
      (fromaddressD = localStorage.getItem("fromaddress"));
  // }, []);

  if (!valueEmail) {

    // navigate("/");
    // console.log('sjdjsj')
    return <Navigate to="/" />;}
  else
    return (
      <>
        <main className="py-20 flex flex-col gap-8">
          <section className="flex flex-col gap-4 justify-center items-center w-full p-4">
            <h1 className="md:text-4xl text-2xl font-monument tracking-wider text-center text-blackish font-bold uppercase">
              Generate Wallet <span className="text-blue-100">Address</span>
            </h1>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 p-4 md:w-4/5 w-11/12">
              <div className="flex flex-col gap-4 bg-gradient-to-l from-blue1 to-blue2 rounded-xl py-3 px-5 text-white w-full">
                <div className="flex flex-col gap-1 lg:w-1/2 w-full">
                  <label className="font-medium">Select Blockchain</label>
                  <select
                    className="border outline-none rounded-lg px-4 py-1 bg-white text-blackish"
                    onChange={(e) => selectAddress(e)}
                  >
                    <option>Select Blockchain</option>
                    <option value="ERC20">ERC20</option>
                    <option value="TRC20">TRC20 </option>
                    {/* <option value="BEP20">BEP20</option>s */}
                    <option value="SOL">SOL</option>
                  </select>
                </div>

                <h1 className="text-sm font-normal">
                  *{" "}
                  <span className="font-semibold text-red-600">
                    Important:{" "}
                  </span>
                  Send only selected coin or token to this deposit address.
                  Sending any other currency to this address may result in loss
                  of your deposit.
                </h1>
                <h1>
                  * Minimum deposit amount is{" "}
                  <span className="text-red-600 font-semibold">5 USDT</span>.
                  Any deposit less than{" "}
                  <span className="text-red-600 font-semibold">5 USDT</span>{" "}
                  will not be credited to your account.
                </h1>
                <h1>
                  * Maximum deposit amount is{" "}
                  <span className="text-red-600 font-semibold">10000 USDT</span>
                  . Any deposit more than{" "}
                  <span className="text-red-600 font-semibold">10000 USDT</span>{" "}
                  will not be credited to your account.
                </h1>
              </div>
              <div className="rounded-xl bg-gradient-to-l from-blue1 to-blue2 flex flex-col gap-3 text-white py-3 px-5 w-full">
                <h3>
                  Address:{" "}
                  <span className="text-red-600 font-semibold">{token}</span>
                </h3>
                {token && (
                  <>
                    <div className="flex gap-2">
                      <p className="text-xs overflow-ellipsis">{myaddress}</p>
                      <BiCopy
                        className="cursor-pointer"
                        onClick={copyFunction}
                      />
                    </div>
                    {isDisplayed && <span>Copied!</span>}
                    <div className="self-center">
                      <QRCode value={myaddress} />
                    </div>
                    <div className="text-xs">
                      <h1>
                        Send only{" "}
                        <span className="text-red-600 font-semibold">USDT</span>{" "}
                        to this deposit address.
                      </h1>
                      <h1>
                        Sending coin or token other than{" "}
                        <span className="text-red-600 font-semibold">USDT</span>{" "}
                        to this address may result in the loss of your deposit.
                      </h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
          <section className="home-j flex flex-col gap-4 justify-center items-center w-full p-4">
            <h1 className="md:text-4xl text-2xl font-monument tracking-wider text-center text-blackish font-bold uppercase">
              Transaction <span className="text-blue-100">Details</span>
            </h1>
            <div className="md:w-2/5 w-10/12 flex flex-col justify-center items-center gap-4 text-base py-4 h-full">
              <p className="text-sm font-medium text-center text-red-600">
                <span className="font-semibold text-blackish">Note: </span>
                Submit transaction details form once the transaction confirmed
                on blockchain
              </p>
              <Formik
                initialValues={{
                  email: enterEmail,
                  transaction: transactionD,
                  amount: amountD,
                  toaddress: toaddressD,
                  fromaddress: fromaddressD,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmitForm(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    className="flex flex-col gap-8 p-8 w-full rounded-xl bg-white shadow-xl"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col gap-1">
                      <label className="font-medium">Email Address</label>
                      <input
                        type="email"
                        className="border outline-none rounded-lg px-4 py-1"
                        placeholder="Enter Your Email"
                        name="email"
                        id="email"
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem("email", e.target.value);
                        }}
                        value={values.email}
                        onBlur={handleBlur}
                        readOnly
                      />
                      {errors.email && touched.email && (
                        <span className="text-blue2 text-sm font-medium">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-medium">To Address</label>
                      <input
                        type="text"
                        className="border outline-none rounded-lg px-4 py-1"
                        placeholder="Enter To Address"
                        name="toaddress"
                        id="toaddress"
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem("toaddress", e.target.value);
                        }}
                        value={values.toaddress}
                        onBlur={handleBlur}
                      />
                      {errors.toaddress && touched.toaddress && (
                        <span className="text-blue2 text-sm font-medium">
                          {errors.toaddress}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-medium">From Address</label>
                      <input
                        type="text"
                        className="border outline-none rounded-lg px-4 py-1"
                        placeholder="Enter From Address"
                        name="fromaddress"
                        id="fromaddress"
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem("fromaddress", e.target.value);
                        }}
                        value={values.fromaddress}
                        onBlur={handleBlur}
                      />
                      {errors.fromaddress && touched.fromaddress && (
                        <span className="text-blue2 text-sm font-medium">
                          {errors.fromaddress}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-medium">Amount (USDT)</label>
                      <input
                        type="number"
                        className="border outline-none rounded-lg px-4 py-1"
                        placeholder="Enter Amount"
                        name="amount"
                        id="amount"
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem("amount", e.target.value);
                        }}
                        value={values.amount}
                        onBlur={handleBlur}
                        min="5"
                        max="10000"
                      />
                      <label className="text-right text-blue1 font-medium">
                        = {Number(values.amount) * 3.33} BTRO
                      </label>
                      {errors.amount && touched.amount && (
                        <span className="text-blue2 text-sm font-medium">
                          {errors.amount}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <label className="font-medium">Transaction Hash</label>
                      <input
                        type="text"
                        className="border outline-none rounded-lg px-4 py-1"
                        placeholder="Enter Transaction Hash"
                        name="transaction"
                        id="transaction"
                        ref={hashInput}
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem("transaction", e.target.value);
                        }}
                        value={values.transaction}
                        onBlur={handleBlur}
                      />
                      <div className="flex justify-between gap-2 text-sm font-medium">
                        <button
                          type="button"
                          className="rounded-lg bg-gradient-to-l from-blue1 to-blue2 outline-none text-white py-2 px-3"
                          onClick={handleValidate}
                        >
                          Validate
                        </button>
                        {transactionFee > 0 && (
                          <h1 className="text-blue2 text-sm font-medium">
                            Transaction Fees: {transactionFee}
                          </h1>
                        )}
                        {valid && (
                          <h1 className="text-blue2 text-sm font-medium">
                            Enter valid Transaction Hash
                          </h1>
                        )}
                      </div>
                      {errors.transaction && touched.transaction && (
                        <span className="text-blue2 text-sm font-medium">
                          {errors.transaction}
                        </span>
                      )}
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className={`${
                          disable
                            ? "bg-grayish text-blackish border border-grayish"
                            : `bg-gradient-to-l from-blue1 to-blue2  text-white border-none`
                        }outline-none py-2 px-4 rounded-lg`}
                        disabled={disable}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </section>
        </main>
        <Wallet open={open} close={handleClose}>
          <div className="px-2 py-8 font-medium flex flex-col gap-3 justify-between items-center w-full ">
            <h1 className="text-xl font-bold text-center">
              Please check your email for transaction details.
            </h1>
            <button
              className="text-base rounded-xl bg-grayish text-blue1 py-2 px-4 border"
              onClick={() => navigate("/")}
            >
              Go to Home
            </button>
          </div>
        </Wallet>
        <Wallet1 open={open1} close={handleClose1}>
        <div className="px-2 py-8 font-medium flex flex-col text-center gap-3 justify-between items-center w-full ">
          <h1 className="text-xl font-bold">Transaction Hash already exist.</h1>
          <h1 className="text-xl font-bold">Please use another Transaction Hash.</h1>
          <button
            className="text-base rounded-xl bg-blue1 text-white py-2 px-4 border"
            onClick={handleClose1}
          >
            Close
          </button>
        </div>
      </Wallet1>
      </>
    );
};

export default TransactionDetail;
