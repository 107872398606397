import React from "react";
import logo from "./asssets/Mask Group logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, useNavigate } from "react-router-dom";
import Drawer from "./Drawer";
import Wallet from "./common/Popup";
import Wallet1 from "./common/Popup";
import * as Yup from "yup";
import { Formik } from "formik";
import { API_URL } from "../config/api-url.constants";
import { CgClose } from "react-icons/cg";
import { FaChevronDown } from "react-icons/fa";

const Headers = () => {
  const [openDrawer, setDrawer] = React.useState(false);
  const [scrolling, setScrolling] = React.useState(false);

  // const [open, setOpen] = React.useState(false);
  // const [open1, setOpen1] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleOpen1 = () => {
  //   setOpen1(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose1 = () => {
  //   setOpen1(false);
  // };

  const navigate = useNavigate()

  window.onscroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    // console.log(scrollTop);
    if (scrollTop > 10) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  // const handleSubmitForm = async (val) => {
  //   const data = {
  //     email: val.email,
  //   };

  //   try {
  //     const fetchResponse = await fetch(API_URL.VerifyEmail, {
  //       method: "POST",
  //       body: JSON.stringify(data),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "origin": "*",
  //       },
  //     });
  //     let response = await fetchResponse.json();
  //     console.log(response)
  //     if (response.message === "Email already exist") {
  //       handleClose()
  //       navigate({pathname: "/transaction", search: `email=${val.email}`})
  //     } else {
  //       handleOpen1();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .matches(
  //       "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
  //       "Enter a valid email"
  //     )
  //     .required("Required"),
  // });

  let activeStyle = {
    borderBottom: "3px double #0093E5",
  };

  return (
    <>
      <header
        className={`flex justify-between fixed w-full items-center lg:px-16 px-8 whitespace-nowrap text-base text-blackish z-50 lg:py-2 py-3 transition-colors duration-200 ${
          scrolling ? "bg-grayish" : "bg-transparent"
        }`}
      >
        <div className="lg:pl-12 pl-0">
          <NavLink
            to="/"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <img src={logo} alt="" className="h-12" />
          </NavLink>
        </div>
        <div className="lg:flex justify-end items-center gap-10 outline-none hidden">
          <div className="border-b-0 hover:border-b border-blue1">
            <NavLink
              to="/"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Home</span>
            </NavLink>
          </div>
          <div className="border-b-0 hover:border-b border-blue1">
            <NavLink
              to="/our-token"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Our token</span>
            </NavLink>
          </div>
          <div className="border-b-0 hover:border-b border-blue1">
            <NavLink
              to="/license"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>License</span>
            </NavLink>
          </div>
          {/* <div className="border-b-0 hover:border-b border-blue1">
            <NavLink
              to="/about"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Company</span>
            </NavLink>
          </div> */}
          <div className="relative border-b-0 hover:border-b border-blue1 group">
          <NavLink
            to="/about"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <span className="flex items-center gap-1">
              Company
              <FaChevronDown className="text-sm transform group-hover:-translate-y-1 transition duration-200" />
            </span>
          </NavLink>
          <div className="absolute">
            <div className="flex flex-col p-8 bg-white rounded-lg -left-8 gap-6 text-lg tracking-wider text-blackish whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:shadow-2xl">
              <NavLink
                to="/about"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="py-1 hover:text-blue1">About Us</span>
              </NavLink>
              <NavLink
                to="/loyalty"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="py-1 hover:text-blue1">Loyalty Levels</span>
              </NavLink>
              {/* <NavLink
                to="/roadmap"
                style={({ isActive }) => (isActive ? textStyleColor : undefined)}
              >
                <span className="py-1 hover:text-blue1">Roadmap</span>
              </NavLink>
              <span className="py-1 hover:text-blue1">Marketing Deck</span> */}
            </div>
          </div>
        </div>
          <div className="border-b-0 hover:border-b border-blue1">
            <NavLink
              to="/contact"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Contact</span>
            </NavLink>
          </div>
          {/* <button
            className="bg-gradient-to-r from-blue1 to-blue2 outline-none text-white p-2 font-medium rounded-lg"
            onClick={handleOpen}
          >
            Get Wallet Address
          </button> */}
        </div>
        <div className="lg:hidden flex w-full justify-end">
          <GiHamburgerMenu
            className="md:text-4xl text-3xl cursor-pointer"
            onClick={() => setDrawer(true)}
          />
        </div>
        {openDrawer && <Drawer setDrawer={setDrawer} />}
      </header>
      {/* <Wallet open={open} close={handleClose}>
        <div className="px-8 py-8 font-medium flex flex-col gap-3 justify-between items-center w-full ">
          <div className="w-full text-right flex justify-between items-center">
            <h1 className="text-lg font-semibold text-blackish">
              Get Wallet Address
            </h1>
            <CgClose
            className="md:text-2xl text-xl cursor-pointer"
            onClick={handleClose}
            />
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className="flex flex-col gap-8 w-full"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-1">
                  <label className="font-medium">Email Address</label>
                  <input
                    type="email"
                    className="border outline-none rounded-lg px-4 py-1"
                    placeholder="Enter Your Email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <span className="text-blue2 text-sm font-medium">
                      {errors.email}
                    </span>
                  )}
                </div>
                <button
                  type="submit"
                  className="bg-gradient-to-r from-blue1 to-blue2 outline-none text-white p-2 rounded-lg"
                >
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Wallet>
      <Wallet1 open={open1} close={handleClose1}>
        <div className="px-2 py-8 font-medium flex flex-col gap-3 justify-between items-center w-full ">
          <h1 className="text-xl font-bold">Please Subscribe first</h1>
          <button
            className="text-base rounded-xl bg-blue1 text-white py-2 px-4 border"
            onClick={() => {handleClose1(); handleClose()}}
          >
            Close
          </button>
        </div>
      </Wallet1> */}
    </>
  );
};

export default Headers;
