import React from "react";
import { scrollToTop } from "../common/Common";
import TabComponent from "../common/TabComponent";

const Loyalty = () => {
  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <main className="">
      <section className="flex justify-center items-center w-full pt-24">
        <div className="w-11/12 flex flex-col justify-center items-center gap-8 h-full">
          <h1 className="md:text-5xl text-3xl divacking-wider font-extrabold text-center w-full font-monument uppercase">
            Bitorio <span className="text-blue-100">Loyalty </span>Levels
          </h1>

          <TabComponent className="" />
        </div>
      </section>
      <section className="flex flex-col justify-center items-center w-full py-8">
        <div className="md:w-4/5 w-11/12 flex flex-col justify-center items-start gap-8 h-full">
          <h1 className="md:text-xl text-lg font-extrabold">
            Airdrop Distribution For Hedgexians
          </h1>
          <div className="w-full font-medium md:text-sm rounded-xl text-xs bg-gradient-to-r from-blue1 to-blue2 text-white">
            <div className="grid grid-cols-3 text-center border-b rounded-tr-xl rounded-tl-xl border-white bg-blue-100 text-blackish md:text-lg text-base font-bold">
              <div className="px-2 py-4">FROM</div>
              <div className="px-2 py-4">TO</div>
              <div className="px-2 py-4">% on Hedging Portfolio</div>
            </div>
            <div className="grid grid-cols-3 text-center border-b border-white">
              <div className="px-2 py-4">1st January 2021</div>
              <div className="px-2 py-4">31st March 2021</div>
              <div className="px-2 py-4">2</div>
            </div>
            <div className="grid grid-cols-3 text-center border-b border-white">
              <div className="px-2 py-4">1st April 2021</div>
              <div className="px-2 py-4">31st June 2021</div>
              <div className="px-2 py-4">3</div>
            </div>
            <div className="grid grid-cols-3 text-center border-b border-white">
              <div className="px-2 py-4">1st July 2021</div>
              <div className="px-2 py-4">31st September 2021</div>
              <div className="px-2 py-4">4</div>
            </div>
            <div className="grid grid-cols-3 text-center border-b border-white">
              <div className="px-2 py-4">1st October 2021</div>
              <div className="px-2 py-4">31st December 2021</div>
              <div className="px-2 py-4">5</div>
            </div>
            <div className="grid grid-cols-3 text-center border-b border-white">
              <div className="px-2 py-4">1st January 2022</div>
              <div className="px-2 py-4">26th April 2022</div>
              <div className="px-2 py-4">6</div>
            </div>
          </div>
        </div>
        <div className="pt-8 md:w-4/5 w-11/12 font-medium">
          <p className="font-semibold pt-5 pb-1 text-blackish">Note:</p>
          <p>
            * Verify your Hedgex Exchange account and deposit $1000 USD worth of
            hedging contract and receive 20$ worth of BTRO tokens in airdrop
          </p>
        </div>
      </section>
    </main>
  );
};

export default Loyalty;
