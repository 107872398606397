import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Footers from "./components/Footers";
import Headers from "./components/Headers";
import React, { Suspense } from "react";
import DotLoader from "react-spinners/HashLoader";
import TransactionDetail from "./components/pages/TransactionDetail";
import Loyalty from "./components/pages/Loyalty";

const Home = React.lazy(() => import("./components/pages/Home"));
const Token = React.lazy(() => import("./components/pages/Token"));
const License = React.lazy(() => import("./components/pages/License"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const JoinUs = React.lazy(() => import("./components/pages/JoinUs"));
const PageNotFound = React.lazy(() =>
  import("./components/pages/PageNotFound")
);
// const Roadmap = React.lazy(() => import("./components/pages/Roadmap"))

function App() {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div className="h-screen flex justify-center items-center">
              <DotLoader size="150px" color="#0093E5" />
            </div>
          }
        >
          <Headers />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-token" element={<Token />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/join-us" element={<JoinUs />} />
            <Route path="/loyalty" element={<Loyalty />} />
            <Route path="/transaction" element={<TransactionDetail />} />
            {/* <Route path="/roadmap" element={<Roadmap />} /> */}
            <Route path="/license" element={<License />} />
            <Route path="/privacy-policy" element={<Terms />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footers />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
