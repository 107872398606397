import { environment } from "../config/environment";

const baseUrl = `${environment.API_URL}`;
export const EXPLORER_LINK = `${environment.EXPLORER_LINK}`;

export const API_URL = {
  Subscribe: `${baseUrl}subscriber/subscribe`,
  TransactionDetail: `${baseUrl}transaction/transactionDetail`,
  ValidateTxHash: `${baseUrl}transaction/validateTxHash`,
  VerifyEmail: `${baseUrl}subscriber/verifyEmail`,
};
