import React, { useEffect } from "react";
import { useState } from "react";

const TabComponent = () => {
  const ContentOne = () => {
    return (
      <div
        className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:py-8 py-4"
        // onMouseEnter={handleMouseEnterEffect}
        // onMouseLeave={handleMouseLeaveEffect}
      >
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            BASIC
          </h1>
          <p className="text-center">Up to 3% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            High LTV Interest Rate
          </h2>
          <h2 className="text-xl font-semibold">18%</h2>
          <h2 className="font-normal text-sm text-center">
            Borrow at live price in %
          </h2>
          <h2 className="text-xl font-semibold">30%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            STANDARD
          </h1>
          <p className="text-center">3% - 19% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            High LTV Interest Rate
          </h2>
          <h2 className="text-xl font-semibold">17%</h2>
          <h2 className="font-normal text-sm text-center">
            Borrow at live price in %
          </h2>
          <h2 className="text-xl font-semibold">40%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM
          </h1>
          <p className="text-center">20% - 29% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            High LTV Interest Rate
          </h2>
          <h2 className="text-xl font-semibold">13%</h2>
          <h2 className="font-normal text-sm text-center">
            Borrow at live price in %
          </h2>
          <h2 className="text-xl font-semibold">50%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM+
          </h1>
          <p className="text-center">Above 30% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            High LTV Interest Rate
          </h2>
          <h2 className="text-xl font-semibold">12%</h2>
          <h2 className="font-normal text-sm text-center">
            Borrow at live price in %
          </h2>
          <h2 className="text-xl font-semibold">70%</h2>
        </div>
      </div>
    );
  };
  const ContentTwo = () => {
    return (
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:py-8 py-4">
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            BASIC
          </h1>
          <p className="text-center">Up to 3% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Exchange Cashback</h2>
          <h2 className="text-xl font-semibold">0.00%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            STANDARD
          </h1>
          <p className="text-center">3% - 19% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Exchange Cashback</h2>
          <h2 className="text-xl font-semibold">0.05%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM
          </h1>
          <p className="text-center">20% - 29% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Exchange Cashback</h2>
          <h2 className="text-xl font-semibold">0.10%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM+
          </h1>
          <p className="text-center">Above 30% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Exchange Cashback</h2>
          <h2 className="text-xl font-semibold">0.25%</h2>
        </div>
      </div>
    );
  };
  const ContentFour = () => {
    return (
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:py-8 py-4">
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            BASIC
          </h1>
          <p className="text-center">Up to 3% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            Free Crypto Withdrawals per Month
          </h2>
          <h2 className="text-xl font-semibold">0</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Profit Sharing
          </h2>
          <h2 className="text-xl font-semibold">30%</h2>
          <h2 className="font-normal text-sm text-center">
            Discount on trading fees
            <br />
            (Spot and Margin Trading )
          </h2>
          <h2 className="text-xl font-semibold">5%</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Brokerage Distribution as per affiliate Program in %
          </h2>
          <h2 className="text-xl font-semibold">70%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            STANDARD
          </h1>
          <p className="text-center">3% - 19% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            Free Crypto Withdrawals per Month
          </h2>
          <h2 className="text-xl font-semibold">1</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Profit Sharing
          </h2>
          <h2 className="text-xl font-semibold">40%</h2>
          <h2 className="font-normal text-sm text-center">
            Discount on trading fees
            <br />
            (Spot and Margin Trading )
          </h2>
          <h2 className="text-xl font-semibold">10%</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Brokerage Distribution as per affiliate Program in %
          </h2>
          <h2 className="text-xl font-semibold">80%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM
          </h1>
          <p className="text-center">20% - 29% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            Free Crypto Withdrawals per Month
          </h2>
          <h2 className="text-xl font-semibold">2</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Profit Sharing
          </h2>
          <h2 className="text-xl font-semibold">50%</h2>
          <h2 className="font-normal text-sm text-center">
            Discount on trading fees
            <br />
            (Spot and Margin Trading )
          </h2>
          <h2 className="text-xl font-semibold">15%</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Brokerage Distribution as per affiliate Program in %
          </h2>
          <h2 className="text-xl font-semibold">90%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM+
          </h1>
          <p className="text-center">Above 30% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">
            Free Crypto Withdrawals per Month
          </h2>
          <h2 className="text-xl font-semibold">3</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Profit Sharing
          </h2>
          <h2 className="text-xl font-semibold">60%</h2>
          <h2 className="font-normal text-sm text-center">
            Discount on trading fees
            <br />
            (Spot and Margin Trading )
          </h2>
          <h2 className="text-xl font-semibold">25%</h2>
          <h2 className="font-normal text-sm text-center">
            Hedging Brokerage Distribution as per affiliate Program in %
          </h2>
          <h2 className="text-xl font-semibold">100%</h2>
        </div>
      </div>
    );
  };

  const ContentThree = () => {
    return (
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:py-8 py-4">
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            BASIC
          </h1>
          <p className="text-center">Up to 3% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Cryptocurrencies</h2>
          <h2 className="text-xl font-semibold">2%</h2>
          <h2 className="font-normal text-sm text-center">HGX</h2>
          <h2 className="text-xl font-semibold">4%</h2>
          <h2 className="font-normal text-sm text-center">Stable coins</h2>
          <h2 className="text-xl font-semibold">3%</h2>
          <h2 className="font-normal text-sm text-center">BTRO Tokens</h2>
          <h2 className="text-xl font-semibold">4%</h2>
          <h2 className="font-normal text-sm text-center">
            Earn in BTRO Bonus
          </h2>
          <h2 className="text-xl font-semibold">+0%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            STANDARD
          </h1>
          <p className="text-center">3% - 19% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Cryptocurrencies</h2>
          <h2 className="text-xl font-semibold">3%</h2>
          <h2 className="font-normal text-sm text-center">HGX</h2>
          <h2 className="text-xl font-semibold">5%</h2>
          <h2 className="font-normal text-sm text-center">Stable coins</h2>
          <h2 className="text-xl font-semibold">4%</h2>
          <h2 className="font-normal text-sm text-center">BTRO Tokens</h2>
          <h2 className="text-xl font-semibold">5%</h2>
          <h2 className="font-normal text-sm text-center">
            Earn in BTRO Bonus
          </h2>
          <h2 className="text-xl font-semibold">+0.1%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM
          </h1>
          <p className="text-center">20% - 29% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Cryptocurrencies</h2>
          <h2 className="text-xl font-semibold">3.5%</h2>
          <h2 className="font-normal text-sm text-center">HGX</h2>
          <h2 className="text-xl font-semibold">6%</h2>
          <h2 className="font-normal text-sm text-center">Stable coins</h2>
          <h2 className="text-xl font-semibold">5%</h2>
          <h2 className="font-normal text-sm text-center">BTRO Tokens</h2>
          <h2 className="text-xl font-semibold">6%</h2>
          <h2 className="font-normal text-sm text-center">
            Earn in BTRO Bonus
          </h2>
          <h2 className="text-xl font-semibold">+0.25%</h2>
        </div>
        <div className="rounded-xl flex flex-col gap-2 justify-center items-center border border-white bg-white bg-opacity-20 shadow-xl backdrop-filter backdrop-blur-lg p-4 text-base">
          <h1 className="font-monument font-bold tracking-widest text-2xl text-center">
            PREMIUM+
          </h1>
          <p className="text-center">Above 30% BTRO in Portfolio</p>
          <hr className="border border-blue-400 w-full" />
          <h2 className="font-normal text-sm text-center">Cryptocurrencies</h2>
          <h2 className="text-xl font-semibold">4%</h2>
          <h2 className="font-normal text-sm text-center">HGX</h2>
          <h2 className="text-xl font-semibold">8%</h2>
          <h2 className="font-normal text-sm text-center">Stable coins</h2>
          <h2 className="text-xl font-semibold">6%</h2>
          <h2 className="font-normal text-sm text-center">BTRO Tokens</h2>
          <h2 className="text-xl font-semibold">7%</h2>
          <h2 className="font-normal text-sm text-center">
            Earn in BTRO Bonus
          </h2>
          <h2 className="text-xl font-semibold">+1%</h2>
        </div>
      </div>
    );
  };

  const tabContent = [
    {
      id: 1,
      heading: "Borrow Benefits",
      content: () => <ContentOne />,
    },
    {
      id: 2,
      heading: "Exchange Benefits",
      content: () => <ContentTwo />,
    },
    {
      id: 3,
      heading: "Earn Benefits",
      content: () => <ContentThree />,
    },
    {
      id: 4,
      heading: "Additional Benefits",
      content: () => <ContentFour />,
    },
  ];

  const [activeTab, setActiveTab] = useState(1);
  // const [isPaused, setPaused] = useState(false);

  function handleTabClick(currentTab) {
    setActiveTab(currentTab);
  }

  // function handleMouseEnterEffect() {
  //   setPaused(true);
  // }

  // function handleMouseLeaveEffect() {
  //   setPaused(false);
  // }

  // console.log(currentTab.content(currentTab.heading));

  // useEffect(() => {
  //   let timer = null;
  //   if (!isPaused) {
  //     timer = setInterval(() => {
  //       setActiveTab((previous) => {
  //         if (previous < tabContent.length) {
  //           return previous + 1;
  //         } else {
  //           return 1;
  //         }
  //       });
  //     }, 2000);

  //     return () => {
  //       timer && clearInterval(timer);
  //     };
  //   }
  // }, [isPaused]);

  return (
    <div className="flex justify-center items-center loyal shadow-xl text-blackish w-full border border-white rounded-xl p-4">
      <div className="w-ful flex flex-col items-center justify-center">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 gap-4 p-2">
          {tabContent.map((item) => {
            return (
              <div
                key={item.id}
                className={`text-base cursor-pointer lg:w-full flex justify-center rounded-full border-2
                                ${
                                  activeTab === item.id
                                    ? "bg-white bg-opacity-20 shadow-lg backdrop-filter backdrop-blur-lg border-blue1"
                                    : "border-white"
                                }
                            `}
                onClick={() => handleTabClick(item.id)}
              >
                <p className="whitespace-nowrap font-medium px-3 py-1">
                  {item.heading}
                </p>
              </div>
            );
          })}
        </div>
        <div
          className="md:p-4 p-2"
          // onMouseEnter={() => setPaused(true)}
          // onMouseLeave={() => setPaused(false)}
        >
          {tabContent[activeTab - 1]
            .content()
            .type(tabContent[activeTab - 1].heading)}
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
