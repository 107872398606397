import React, { memo, useCallback, useRef } from "react";
import { useOutsideClick } from "./Common";

const Popup = memo(({ open, close, children }) => {
  // console.log("Hello");
  const ref = useRef();
  const containerRef = useRef();

  useOutsideClick(ref, containerRef, close);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      close();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return (
    <>
      {open && (
        <div
          ref={containerRef}
          className={`fixed flex justify-center items-center h-screen w-screen top-0 right-0 left-0 z-50 bg-black bg-opacity-50`}
        >
          <div
            className="md:w-1/3 w-11/12 text-black bg-white rounded-xl"
            ref={ref}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
});

export default Popup;
